import { useEffect, useState } from 'react';
import {
  FormProvider,
  type SubmitErrorHandler,
  type SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import {
  type LoaderFunction,
  redirect,
  useLoaderData,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  SEARCH_PARAMS,
  getDynamicTextColor,
  isDarkColor,
  useLanguageStore,
  useNotApplicableStore,
} from '@trustyou/shared';
import {
  MAX_CARD_WIDTH_PX,
  SurveyFooter,
  SurveyLanguageSelector,
  submissionMessages,
} from '@trustyou/survey-manager';
import { Alert, Box, Stack, theme as defaultTheme, snackbar } from '@trustyou/ui';

import type {
  CreateSurveyFeedbackUseCaseIn,
  FeedbackSourceEnum,
  GetSurveyQuestionnaireUseCaseOut,
  Language,
} from './client';
import { useSurveyTheme } from './hooks/use-survey-theme';
import { PageContent } from './page-content';
import { denormalizeScore, findLocale, getLanguageOptions, isMaxValue, isQuestion } from './utils';
import { createValidationSchema, scrollToFirstError } from './utils/validation';

import { fetchQuestionnaire, submitSurvey } from './service/api';
import { generateValue } from './store';
import { usePublishToSourceStore } from './store/publish-to-source-store';

export type FormValue = string | number | boolean | null;

export type FormValues = {
  [questionId: string]: FormValue | FormValue[];
};

export type SurveyData = {
  data: GetSurveyQuestionnaireUseCaseOut;
  outboundId: CreateSurveyFeedbackUseCaseIn['outbound_id'];
  source: CreateSurveyFeedbackUseCaseIn['source'];
  overallScore: number | null;
};

const loader: LoaderFunction = async ({ request, params }) => {
  const { organizationId = '', entityId = '', surveyId = '' } = params;

  const urlSearchParams = new URLSearchParams(window.location.search);

  const source = urlSearchParams.get(SEARCH_PARAMS.source) as FeedbackSourceEnum;
  const preview = urlSearchParams.get(SEARCH_PARAMS.preview) === 'true';
  const outboundIdFromURL = urlSearchParams.get(SEARCH_PARAMS.outboundId);
  const overallScore = parseInt(String(urlSearchParams.get(SEARCH_PARAMS.overallScore)));

  const outboundId = outboundIdFromURL ?? generateValue().outboundId;

  // const { default: data } = await import('./mocks/survey-with-prefilled-answers.mock.json');
  const data = await fetchQuestionnaire({
    organizationId,
    entityId,
    surveyId,
    outboundId,
    source,
    preview,
  });
  if ('redirect_to' in data) {
    return redirect(data.redirect_to);
  }

  const loaderData: SurveyData = {
    data,
    outboundId,
    source,
    overallScore,
  };

  return loaderData;
};

export default function Survey() {
  const [searchParams] = useSearchParams();
  const isPreview = searchParams.get(SEARCH_PARAMS.preview) === 'true';
  const shouldAutoReload = searchParams.get(SEARCH_PARAMS.reload) === 'true';

  const intl = useIntl();
  const errorMessages = {
    mandatory: intl.formatMessage(submissionMessages.mandatoryQuestion),
    phone: intl.formatMessage(submissionMessages.phoneNumberErrorHelperText),
  };

  const { organizationId = '', entityId = '', surveyId = '' } = useParams();
  const surveyData = useLoaderData() as SurveyData;
  const { data, outboundId, source, overallScore } = surveyData;
  const { questionnaire, entity, available_languages } = data;

  const questions = Object.values(surveyData.data.questionnaire.content_items).filter(isQuestion);

  const notApplicableMap = useNotApplicableStore((state) => state.notApplicableMap);

  const [shouldShowThankYouPage, setShouldShowThankYouPage] = useState(false);

  const methods = useForm<FormValues>({
    defaultValues: questions.reduce<FormValues>((acc, question) => {
      if (question.prefilled_answer) {
        acc[question.id] = question.prefilled_answer.value;
      }
      if (question.handling_type === 'overall_score' && overallScore) {
        const maxValue = Number(question.primitive.validations?.find(isMaxValue)?.value);
        const denormalizedOverallScore = denormalizeScore(overallScore, maxValue);
        acc[question.id] = denormalizedOverallScore;
      }
      return acc;
    }, {}),
    resolver: (data, context, options) => {
      const validationSchema = createValidationSchema(
        questions,
        methods.watch(),
        errorMessages,
        notApplicableMap
      );
      return zodResolver(validationSchema)(data, context, options);
    },
    mode: 'onChange',
  });
  const { handleSubmit } = methods;

  const { setLocale } = useLanguageStore();
  const [language, setLanguage] = useState(questionnaire?.default_language ?? 'en');
  useEffect(() => {
    setLocale(findLocale(language));
  }, [language, setLocale]);

  const { surveyTheme } = useSurveyTheme(questionnaire.theme);
  const theme = surveyTheme ?? defaultTheme;
  const outsideTextColor = getDynamicTextColor(theme);
  const hasDarkBackground = isDarkColor(theme.palette.background.default);

  const setEntityId = usePublishToSourceStore((state) => state.setEntityId);
  useEffect(() => {
    setEntityId(entityId);
  }, [entityId, setEntityId]);

  const setIsPublishToSourceDialogOpen = usePublishToSourceStore((state) => state.setIsDialogOpen);

  const publishToSourceQuestionId = usePublishToSourceStore(
    (state) => state.publishToSourceQuestionId
  );

  const handleChangeLanguage = (shortLanguageCode: Language) => {
    setLanguage(shortLanguageCode);
    setLocale(findLocale(shortLanguageCode));
  };

  const onValid: SubmitHandler<FormValues> = async (data) => {
    const surveyFeedback = Object.entries(data).map(([questionId, value]) => ({
      question_id: questionId,
      // Ensure that 'value' is present in the payload
      value: value ?? null,
      // Add 'not_applicable' key only if its checkbox is checked
      ...(notApplicableMap.get(questionId) && { not_applicable: true }),
    }));

    try {
      // console.log('Survey feedback:', surveyFeedback);
      await submitSurvey({
        organizationId,
        entityId,
        surveyId,
        surveyFeedback,
        outboundId,
        source,
      });

      setShouldShowThankYouPage(true);

      const isPublishToSourceChecked = surveyFeedback.find(
        (item) => item.question_id === publishToSourceQuestionId
      )?.value;
      if (isPublishToSourceChecked) {
        setIsPublishToSourceDialogOpen(true);
      }
    } catch (error) {
      snackbar.error('Failed to submit data.');
      console.error('Failed to submit data.', error);
    }
  };

  // TODO: Pass refs to children components to scroll to first errored question in current page
  // const { registerFieldRef, scrollToError } = useScrollToError({ errors, setFocus });

  const onInvalid: SubmitErrorHandler<FormValues> = (errors) => {
    window.scroll({ top: 0 });
    scrollToFirstError(errors); // Only works for text fields in submit section
    // TODO: Implement a way to scroll to the first errored question in the current page for any custom question type
    console.warn(errors);
  };

  return (
    <FormProvider {...methods}>
      {isPreview && (
        <Alert
          severity="info"
          icon={false}
          sx={{
            justifyContent: 'center',
            borderRadius: 0,
            fontFamily: defaultTheme.typography.body2.fontFamily,
          }}
        >
          {intl.formatMessage(submissionMessages.previewBanner, { surveyName: entity.name })}
        </Alert>
      )}
      <Box
        component="form"
        onSubmit={handleSubmit(onValid, onInvalid)}
        sx={{
          display: 'grid',
          gridTemplateRows: 'auto 1fr auto',
          maxWidth: MAX_CARD_WIDTH_PX,
          minHeight: '100vh',
          margin: 'auto',
          paddingBlock: { xs: 2, sm: 3 },
          gap: { xs: 2, sm: 3 },
        }}
      >
        <Stack direction="row" sx={{ justifyContent: 'end', paddingInline: { xs: 2, sm: 0 } }}>
          <SurveyLanguageSelector
            options={getLanguageOptions(available_languages)}
            value={language}
            onChange={handleChangeLanguage}
          />
        </Stack>
        <Stack>
          <PageContent
            questionnaire={questionnaire}
            entity={entity}
            language={language}
            isPreview={isPreview}
            shouldShowThankYouPage={shouldShowThankYouPage}
            shouldAutoReload={shouldAutoReload}
            outsideTextColor={outsideTextColor}
          />
        </Stack>
        <Stack
          sx={{
            paddingBlockStart: { xs: 4, sm: 6 },
            paddingInline: { sm: 5 },
            gap: 2,
          }}
        >
          <SurveyFooter
            entity={entity}
            textColor={outsideTextColor}
            hasDarkBackground={hasDarkBackground}
          />
        </Stack>
      </Box>
    </FormProvider>
  );
}

Survey.loader = loader;
