import { create } from 'zustand';

type PublishToSourceState = {
  isDialogOpen: boolean;
  setIsDialogOpen: (isDialogOpen: boolean) => void;

  entityId: string;
  setEntityId: (entityId: string) => void;

  reviewTextQuestionId: string;
  setReviewTextQuestionId: (reviewTextQuestionId: string) => void;

  publishToSourceQuestionId: string;
  setPublishToSourceQuestionId: (publishToSourceQuestionId: string) => void;
};

export const usePublishToSourceStore = create<PublishToSourceState>()((set) => ({
  isDialogOpen: true,
  setIsDialogOpen: (isDialogOpen) => set(() => ({ isDialogOpen })),

  reviewTextQuestionId: '',
  setReviewTextQuestionId: (reviewTextQuestionId) => set(() => ({ reviewTextQuestionId })),

  entityId: '',
  setEntityId: (entityId) => set(() => ({ entityId })),

  publishToSourceQuestionId: '',
  setPublishToSourceQuestionId: (publishToSourceQuestionId) =>
    set(() => ({ publishToSourceQuestionId })),
}));
